//
// Content
//

// Desktop mode
@include media-breakpoint-up(lg) {
  .content {
    padding: 0;

    // Aside enabled
    .aside-enabled & {
      padding-left: get($content-spacing, desktop);
    }
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(sm) {
  .content {
    padding:  0;
  }
}
