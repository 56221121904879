//
// Toolbar
//

// General
.toolbar {
  display: flex;
  align-items: center;

  // Custom button
  .btn.btn-custom {
    background-color: rgba($white, 0.1);
  }
}

// Desktop mode
// @include media-breakpoint-up(md) {
//   .toolbar {
//     .py-5 {
//       padding-top: 1.25rem !important;
//       padding-bottom: 0;
//     }
//   }
// }

// // Tablet & mobile modes
// @include media-breakpoint-down(lg) {
//   .toolbar {
//   }
// }
